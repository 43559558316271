@media screen and (max-width: 600px) {
	.buttons__wrapper-3JADS {
		flex-direction: column-reverse;
	}

	.buttons__wrapper-3JADS button {
		width: 100%;
		margin-bottom: 20px;

		border-radius: 50px;
	}

	.group-21kSA {
		flex-direction: column;
		grid-gap: 20px;
		gap: 20px;
	}

	.group-21kSA .group__field-3M1OC {
		width: 100%;
		margin-bottom: 0;
	}

	.group-21kSA .group__field-3M1OC p {
		transform: none;
	}

	button.ffpInfo_button-18tEw:not(:last-child) {
		margin-right: 0;
	}

	.loyaltyConnect_header-2emmh {
		padding: 48px 8px 35px;

		font-weight: 500;
	}
}
