

.avatar-2Twaj {
	background-color: var(--brand-1-0);
}

.avatar-2Twaj span {
	font-weight: 600;
}

.header_content-V_rhL .info-uZbX0 .name-YzaIK {
	font: var(--medium-desktop-accent);
}

.edit-1iiju svg {
	margin-left: 8px;
}

.edit-1iiju,
.empty-3i4Rv .header_content-V_rhL .info-uZbX0 .name-YzaIK,
.empty-3i4Rv .header_content-V_rhL .avatar-2Twaj svg {
	color: var(--brand-1-0);
}
