

.sidebar__title-27lAL {
	display: block;
	margin-bottom: 16px;

	font-weight: 600;
}

.sidebar-3mw2w .sidebar__item-3y1he .sidebar__icon-m_4PP {
	margin-right: 8px;
	min-width: 0;
	min-width: initial;
}

.mobileMenu__title-1pYeN {
	font: var(--big--1-desktop-bold);
}
