

div.wrapper-21a_K {
	border-radius: 8px;

	box-shadow: 4px 4px 33px 0 rgba(0, 0, 0, 0.05);
}

.wrapper-21a_K .header-2AUXN {
	padding: 0 20px;
}

.wrapper-21a_K .header-2AUXN > div[aria-hidden] {
	padding: 0;
	margin-left: 8px;
}

.popup__header-3w79U {
	padding-left: 44px;

	background-color: var(--brand-1--1);
}

.popup__header-3w79U svg {
	display: none;
}

.popup__header-3w79U:before {
	position: absolute;
	left: 12px;

	width: 24px;
	height: 24px;

	background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwIDExSDcuODNMMTMuNDIgNS40MUwxMiA0TDQgMTJMMTIgMjBMMTMuNDEgMTguNTlMNy44MyAxM0gyMFYxMVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=);
	content: '';
}
