button.field__change-25Hdy {
	color: var(--brand-1-0);
}

.field__name-8v9se {
	white-space: break-spaces;
}

button.confirmDialog__button_accent-gxRLM,
button.confirmDialog__button_normal-3742l {
	font: var(--medium-desktop-bold);
}

button.confirmDialog__button_normal-3742l {
	border-radius: 100px;
	border: 1px solid var(--brand-1-0);
}

div .button_loader-3xwPI {
	border-radius: 100px;
}

.resend__back-fZwp6 {
	display: block;
}

.deleteAccount__close-iShoM {
	z-index: 3;
}

.warning__text-l488P a {
	color: var(--white);
}

@media screen and (max-width: 600px) {
	.resend_timer-1PEoG {
		padding-left: 0;

		font-size: 15px;
	}

	.resend_timer-1PEoG:before {
		content: none;
	}

	.resend__back-fZwp6 {
		position: absolute;
		top: 12px;
		left: 12px;

		width: 32px;
		height: 32px;

		border-right: none;
		border-radius: 50%;

		font-size: 0;

		background: var(--bg-fill) url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkFjdGlvbiI+CjxwYXRoIGlkPSJVbmlvbiIgZD0iTTE2LjY2NjcgOS4xNjY4M0g2LjUyNUwxMS4xODMzIDQuNTA4NUwxMCAzLjMzMzVMMy4zMzMzNCAxMC4wMDAyTDEwIDE2LjY2NjhMMTEuMTc1IDE1LjQ5MThMNi41MjUgMTAuODMzNUgxNi42NjY3VjkuMTY2ODNaIiBmaWxsPSIjOTA5MDkwIi8+CjwvZz4KPC9zdmc+Cg==) no-repeat center;
	}
}
