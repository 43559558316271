.actions-3Mb8P button {
	border-radius: 100px;

	font: var(--normal-desktop-bold);
}

@media screen and (max-width: 600px) {
	.actions-3Mb8P button {
		background-color: var(--brand-1-5);
	}
}
