.route__title-1lJE1 {
	margin: 0 0 20px;

	font: var(--big--1-desktop-bold);
}

.active__route-1T1Rs {
	background: inherit;
}

.container_mailSettings-_-B5T {
	display: none;
}

@media screen and (max-width: 600px) {
	.homeUserCard-26V9Q {
		display: none;
	}

	.loginWrapper-3hks3 {
		padding: 84px 28px 47px;
	}

	.orders__wrapper-6-q0X {
		display: grid;
		grid-template-columns: minmax(0, 1fr);
	}

	.profileControls-RLcJY {
		grid-row: 1;
		margin-bottom: 12px;
	}
}
