

.spoilerDetails-1U0a3 .ticketExample-Y2ZC- > div {
	display: none;
}

.spoilerDetails-1U0a3 .ticketExample-Y2ZC- {
	grid-template-columns: 1fr;
}

.spoilerDetails-1U0a3 .ticketExample-Y2ZC-:after {
	width: 100%;

	padding-top: 39.6%;

	background: url('https://cdn.websky.aero/content/frontend/images/itinerary-receipt/example/D2/itinerary-example.jpg')
		no-repeat center / contain;
	content: '';
}

.close-1l6w7 {
	display: none;
}
